/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import axios from 'axios';
import Cookies from "../../helpers/cookies";
// reactstrap components
import {Alert, Button, Col, Container, FormGroup, Input, Row} from "reactstrap";

// core components

class LandingPageHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            button_status: "disabled",
            formClassName: "",
            AlertOpen: false,
            message: null,
            success: false,
            form: {
                email: null,
                message: null
            }
        };
    }

    handleHtmlControlChange = (event) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            "loading": true
        });
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/subscribe/', this.state.form,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                    "Referrer-Policy": "strict-origin-when-cross-origin"
                }
            }).then(response => {
            if (response.status) {
                this.setState({
                    ...this.state,
                    formClassName: "hide",
                    message: "Thank you for your message. We will contact you as soon as possible!",
                    success: true,
                    AlertOpen: true
                });
            } else
                this.setState({
                    ...this.state,
                    message: "Some error happened. Please try again later.",
                    success: false,
                    AlertOpen: true
                });
        }).catch(error => {
            let msg = "Some error happened. Please try again later!";
            if (error.response.data && error.response.data.detail) {
                msg = error.response.data.detail;
            }
            this.setState({
                ...this.state,
                message: msg,
                success: false,
                AlertOpen: true
            });
        }).finally(() => {
            this.setState({
                ...this.state,
                "loading": false
            });
        });
    };

    componentDidMount() {
        let pageHeader = React.createRef();
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
        this.setState({
            ...this.state,
            "pageHeader": pageHeader
        });
    }

    setAlertOpen(value) {
        this.setState({
            ...this.state,
            "AlertOpen": value
        });
    }

    getAlertParams() {
        return this.state.success ? {
            class: "",
            color: "success",
        } : {
            class: "",
            color: "danger"
        };
    }

    render() {
        return (
            <>
                <div
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/landing-back.jpg") + ")",
                    }}
                    className="page-header"
                    data-parallax={true}
                    ref={this.state.pageHeader}
                >
                    <div className="filter"/>
                    <Container>
                        <div className="motto text-center">
                            <h1>Yessofts Development</h1>
                            <h3>Software development company.</h3>
                            <br/>

                            <form onSubmit={this.handleSubmit} className={this.state.formClassName}>
                                <Row>
                                    <Col sm="3"/>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Input placeholder="Leave E-mail to contact with you..." type="email"
                                                   className={"animated-input"} name="email"
                                                   onChange={this.handleHtmlControlChange}
                                                   required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input placeholder="Leave a message to us..." type="text"
                                                   className={"animated-input"} name="message"
                                                   onChange={this.handleHtmlControlChange}
                                                   required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <Button
                                            className="btn-round mr-1"
                                            color="success"
                                            outline
                                            type="submit"
                                        >
                                            <i className={
                                                "fa mr-2 fa-lg " + (this.state.loading ? "fa-circle-o-notch" : "fa-paper-plane")
                                            }/>
                                            Send
                                        </Button>
                                    </Col>
                                    <Col sm="2"/>
                                </Row>
                            </form>
                            <Alert color={this.getAlertParams().color} className={this.getAlertParams().class}
                                   isOpen={this.state.AlertOpen}>
                                <Container>
                                    <span>{this.state.message}</span>
                                </Container>
                            </Alert>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

export default LandingPageHeader;
