import React from "react";
import "../../assets/scss/menu.scss";

function IndexFooter() {
    return (
        <div className="footer">
            <div className="footer-back"></div>
            <div className="copyright">&copy; YesSofts Corp.</div>
            <ul>
                <li><a target="_blank" rel={"noreferrer"} href="https://github.com/hrayr-s">Github</a></li>
                <li><a target="_blank" rel={"noreferrer"}
                       href="https://www.linkedin.com/in/hrayr-stepanyan/">LinkedIn</a></li>
                <li><a target="_blank" rel={"noreferrer"}
                       href="https://www.upwork.com/freelancers/~010d8fb9f3b13d4993">UpWork</a></li>
            </ul>
        </div>
    )
}

export default IndexFooter;